import { useClient } from "~/stores/client";
export default defineNuxtPlugin(() => {
    const router = useRouter();
    const runtimeConfig = useRuntimeConfig();
    const url = runtimeConfig.public.protocol + '://' + runtimeConfig.public.domain + '/api/';

    const IGNORED_PATHS = ['/login', '/logout', '/refresh-token'];

    const $mainFetch = $fetch.create({
        baseURL: url,
        headers: useRequestHeaders(['cookie', 'User-Agent', 'X-Forwarded-For', 'X-Real-IP']),
        retry: 1,
        credentials: 'include',
        onRequest({ options }) {
            if (import.meta.server) {
                options.headers['Client-Ip'] = options.headers['x-real-ip'];
            }

            const client = useClient();
            if (client.isAuthenticated) {
                options.headers['Authorization'] = 'Bearer ' + client.accessToken;
            } else {
                options.headers['Authorization'] = "";
            }
        },
        async onResponseError(ctx) {
            const IS_IGNORE = IGNORED_PATHS.some(path => (ctx.request as string).includes(path));

            const code = ctx.response.status;

            const client = useClient();
            if (code === 401 && !IS_IGNORE) {
                if (ctx.options.retry === 0) {
                    ctx.response = {
                        status: 401,
                        ...ctx.response
                    };
                    router.push('/');
                    await client.signOut();
                    return;
                }
                await client.refreshToken();
                //TODO: Проверка на наличие токена??

                if (client.isAuthenticated) {
                    ctx.options.headers['Authorization'] = 'Bearer ' + client.accessToken;
                }

                ctx.response = {//Костыль для повторной отправки запроса после обновления токена(спасибо ofetch)
                    status: 409,
                    ...ctx.response
                };
            } else if (code === 401) {
                if (!(ctx.request as string).includes('/login')) {
                    router.push('/');
                }
                return Promise.reject();
            } else {
                ctx.options['retry'] = 0;
            }
        }
    });

    return {
        provide: {
            mainFetch: $mainFetch
        }
    }
})
