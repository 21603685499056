import * as vt from 'vue-toastification';

export default defineNuxtPlugin(({ vueApp }) => {
    vueApp.use(vt.default);
    return {
        provide: {
            toast: vt.useToast()
        }
    };
})
