<template>
  <Component
    :is="to === undefined ? (href === undefined ? 'button' : 'a') : NuxtLink"
    ref="appButtonRef"
    :to="to"
    :href="href"
    class="btn"
    :class="{
      'btn-primary': variant === BtnVariant.Primary,
      'btn-accent text-white': variant === BtnVariant.Secondary,
      'btn-apply shadow-none': variant === BtnVariant.Tertiary,
      'btn-white': variant === BtnVariant.White,
      'btn-suggestion shadow-none': variant === BtnVariant.Outline,
      'btn-link': variant === BtnVariant.Link,
      'btn-outline-primary border border-primary': variant === BtnVariant.OutlinePrimary,
      'active': active,
      btn_icon: isIcon,
    }"
  >
    <span
      v-if="!!$slots['prepend-icon']"
      class="btn__prepend"
    >
      <slot name="prepend-icon" />
    </span>
    <span
      class="btn__content"
      :style="contentStyle"
    >
      <slot />
    </span>
    <span
      v-if="!!$slots['append-icon']"
      class="btn__append"
    >
      <slot name="append-icon" />
    </span>
  </Component>
</template>

<script setup lang="ts">
import { BtnVariant } from "~/composables/enums/BtnVariant";
import { NuxtLink } from "#components";

//-----PROPS-----\\
defineProps({
  variant: { type: Number, required: false, default: BtnVariant.Primary },
  to: { type: String, default: undefined },
  href: { type: String, default: undefined },
  active: { type: Boolean, required: false, default: false },
  isIcon: { type: Boolean, required: false, default: false },
  contentStyle: { type: String, default: null }
});

const appButtonRef = ref();
defineExpose({
  getRef: () => appButtonRef.value
})
</script>

<style scoped lang="scss">
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  border-radius: 20px;
  height: 40px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  padding: 0;

  &:not(.btn-suggestion,.btn-link) {
    border: none;
  }

  &_icon {
    width: 40px;
    border-radius: 50%;
  }
  &_icon &__content {
    height: 24px;
    width: 24px;
  }

  &:not(&_icon) &__content {
    white-space: nowrap;
    padding: 0 24px;
    &:not(:first-child) {
      padding-left: 8px;
    }
    &:not(:last-child) {
      padding-right: 8px;
    }
  }
  &.btn-link:not(&_icon) &__content {
    padding: 0 12px;
    &:not(:first-child) {
      padding-left: 8px;
    }
    &:not(:last-child) {
      padding-right: 8px;
    }
    &:not(:last-child):first-child {
      padding-left: 16px;
    }
    &:not(:first-child):last-child {
      padding-right: 16px;
    }
  }

  &__content {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.btn-link {
    .btn__prepend {
      margin-left: 12px;
    }

    .btn__append {
      margin-right: 12px;
    }
  }
  &:not(.btn-link) {
    .btn__prepend {
      margin-left: 16px;
    }

    .btn__append {
      margin-right: 16px;
    }
  }
  &__prepend, &__append {
    display: inline-block;
    height: 18px;
    width: 18px;
  }
}
</style>