<template>
  <div class="container-xxl text-center py-34 py-md-120">
    <div class="error-code text-primary fw-bold">
      {{ error.statusCode }}
    </div>
    <h1 class="mt-15 mt-md-34 mb-10 fw-bold">
      {{ getTitle() }}
    </h1>
    <div class="mb-15 mb-md-34">
      Нам очень жаль за предоставленные неудобства.
    </div>
    <AppButton
      href="/"
      @click.prevent="handleError"
    >
      На главную
    </AppButton>
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app';

const props = defineProps({
  error: Object as () => NuxtError
});

console.log(props.error);

function getTitle(): string {
  if (props.error === undefined || props.error.statusMessage === undefined || props.error.statusMessage === null || props.error.statusMessage === '') {
    return 'Что-то пошло не так';
  }

  return props.error.statusMessage;
}
function handleError() {
  clearError({
    redirect: '/',
  });
}
</script>

<style scoped lang="scss">
.error-code {
  font-size: 150px;
  line-height: 1;
}
</style>