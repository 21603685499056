import { defineStore } from 'pinia';
import { appendHeader } from "h3";

type Client = {
    accessToken: string | null,
    id: string | null,
    name: string | null,
    surname: string | null,
    phone: string | null,
    photo: string | null,
    email: string | null,
    city: { id: number, title: string, alias: string } | null,
    isNotificationChannelConnected: boolean,
    isSummaryCreated: boolean,
    isAdmin: boolean,
    summaryId: string | null,
    companies: Company[],
    activeCompany: Company | null,
    role: number,
}

type Company = {
    id: string,
    name: string,
    img: string,
    isVacancyCreated: boolean,
    vacanciesCount: number,
    type: boolean
};

export const useClient = defineStore('client', {
    state: (): Client => ({
        accessToken: null,
        id: null,
        name: null,
        surname: null,
        phone: null,
        photo: null,
        email: null,
        city: null,
        isNotificationChannelConnected: false,
        isSummaryCreated: false,
        isAdmin: false,
        summaryId: null,
        companies: [],
        activeCompany: null,
        role: 0,
    } as Client),

    actions: {
        async defineCity () {
            const city = useCookie('city');
            let data;

            if (city.value === undefined) {
                const event = useRequestEvent();
                data = await mainFetch('define-city', {
                    async onResponse({ response }) {
                        if (response.headers.get('set-cookie') !== null) {
                            appendHeader(event, 'set-cookie', response.headers.get('set-cookie'));
                        }
                    }
                });
                data = data.data.value;
            } else {
                data = { city: city.value! };
            }
            this.city = { id: data.city.id, title: data.city.title, alias: data.city.alias };
            return true;
        },
        async signIn (username: string, password: string): Promise<any> {
            const promise = mainFetch('login', {
                method: 'POST',
                body: { username, password }
            });

            promise.then((response) => {
                response = response.data.value;
                this.setAccountData({
                    accessToken: response.accessToken,
                    id: response.client.id,
                    name: response.client.name,
                    surname: response.client.surname,
                    phone: response.client.phone,
                    photo: response.client.photo,
                    email: response.client.email,
                    city: response.client.city,
                    isNotificationChannelConnected: response.client.isNotificationChannelConnected,
                    isSummaryCreated: response.client.isSummaryCreated,
                    isAdmin: response.client.isAdmin,
                    summaryId: response.client.summaryId,
                    companies: response.client.companies,
                    activeCompany: response.company??null,
                    role: response.client.role,
                });
            });

            return promise;
        },
        async refreshToken () {
            const event = useRequestEvent();
            const getClientData = this.emptyClientData;

            const response = await mainFetch('refresh-token', {
                method: 'POST',
                body: { getClientData, getCompanyData: false },
                // async onResponse({ response }) {
                onResponse({ response }) {
                    if (!import.meta.client && response.headers.get('set-cookie') !== null) {
                        const cookie: string[]|null = response.headers.get('set-cookie').match(/(?<cookie>([\w_]+=[\w\d\-_.\\/]+;?)( expires=[\w]+, [\d]{2} [\w]+ [\d]{4} [\w]{2}:[\w]{2}:[\w]{2} [\w]+;?)( Max-Age=[\d]+;?)( path=[\w\d_\-/.\\];?)( domain=[\w\d_\-/\\.]+;?)( httponly;?)( samesite=[\w]+;?)?)/g)
                        if (cookie !== null) {
                            cookie.forEach(val => appendHeader(event, 'set-cookie', val));
                        }
                    }
                }
            });
            const data = response.data.value;

            if (data.accessToken !== undefined) {
                if (getClientData) {
                    this.setAccountData({
                        accessToken: data.accessToken,
                        id: data.client.id,
                        name: data.client.name,
                        surname: data.client.surname,
                        phone: data.client.phone,
                        photo: data.client.photo,
                        email: data.client.email,
                        city: data.client.city,
                        isNotificationChannelConnected: data.client.isNotificationChannelConnected,
                        isSummaryCreated: data.client.isSummaryCreated,
                        isAdmin: data.client.isAdmin,
                        summaryId: data.client.summaryId,
                        companies: data.client.companies,
                        activeCompany: data.company,
                        role: data.client.role,
                    });
                } else {
                    this.accessToken = data.accessToken;
                }
            }

            await nextTick();
        },
        async signOut () {
            await mainFetch('logout', {
                method: 'POST'
            }).then(() => {
                const router = useRouter();
                router.push('/');

                this.accessToken = null;
                this.id = null;
                this.name = null;
                this.surname = null;
                this.companies = [];
                this.activeCompany = null;
            });
        },
        loginCompany (company: Company) {
            if (this.activeCompany !== null && this.activeCompany!.id === company.id) {
                return;
            }
            const config = useRuntimeConfig();

            const date = new Date();
            date.setDate(date.getDate() + 60);
            document.cookie = `company=${company.id};domain=.${config.public.domain};expires=${date.toUTCString()};path=/`;

            this.activeCompany = company;
        },
        logoutCompany () {
            if (this.getActiveCompany === null) {
                return;
            }
            const config = useRuntimeConfig();
            document.cookie = `company=;domain=.${config.public.domain};expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/`;
            this.activeCompany = null;
        },
        setAccountData (data: Client) {
            this.accessToken = data.accessToken;
            this.id = data.id;
            this.name = data.name;
            this.surname = data.surname;
            this.phone = data.phone;
            this.photo = data.photo;
            this.email = data.email;
            this.city = data.city;
            this.isNotificationChannelConnected = data.isNotificationChannelConnected;
            this.isSummaryCreated = data.isSummaryCreated;
            this.isAdmin = data.isAdmin;
            this.summaryId = data.summaryId;
            this.companies = data.companies;
            this.activeCompany = data.activeCompany??null;
            this.role = data.role??0;
        }
    },

    getters: {
        isAuthenticated(): boolean {
            return this.id !== null;
        },
        emptyClientData(): boolean {
            return this.name === null;
        },
        isCompanies(): boolean {
            return this.companies.length > 0;
        },
        getClientId(): string | null {
            return this.id;
        },
        getCityId(): number | null {
            return this.city?.id ?? null;
        },
        getCityTitle(): string | null {
            return this.city?.title ?? null;
        },
        getActiveCompany(): Company | null {
            return this.activeCompany;
        },
        getClientEntity(): Client {
            return {
                id: this.id,
                name: this.name,
                surname: this.surname,
                email: this.email
            } as Client;
        }
    }
})
