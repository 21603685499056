<template>
  <div>
    <NuxtLoadingIndicator
      color="var(--bs-primary)"
    />
    <HeaderComponent />
    <NuxtLayout>
      <NuxtPage class="main" />
    </NuxtLayout>
    <FooterComponent />
    <LazyCallComponent
      v-if="client.isAuthenticated && centrifuge !== null"
      :centrifuge="centrifuge"
      @hang-up="call = null"
    />
    <LazyUploadBar v-if="client.isAuthenticated && uploadVideoList.length !== 0" />
    <ModalsContainer class="modal-backdrop fade show" />
    <audio
      ref="newMessageNotification"
      src="/public/chat/new-message.mp3"
    />
  </div>
</template>

<script setup lang="ts">
import 'vue-final-modal/style.css';
import { ModalsContainer } from 'vue-final-modal';
import { useClient } from "~/stores/client";
import { Centrifuge } from "centrifuge";
// import { UploadVideoData } from "~/entities/UploadVideoData";
import type { UploadVideoData } from "~/entities/UploadVideoData";
import type { Notification } from "~/entities/notification";
import { useApiNotifications } from "~/composables/api/notifications";
// import type { NewMessageCount } from "~/entities/NewMessageCount";
import {initializeFaro, EVENT_ROUTE_CHANGE, getWebInstrumentations} from '@grafana/faro-web-sdk';
import type { NewMessageCount } from "~/entities/NewMessageCount";

const runtimeConfig = useRuntimeConfig();
const router = useRouter();
const client = useClient();
const { $toast, callHook } = useNuxtApp();
const { get } = useApiNotifications(client.getClientId);

//-----VARIABLES-----\\
let pingTimer: Timeout|null = null;

//-----STATE-----\\
const searchCity = useState<City>('searchCity', () => client.city);
const call = useState<{ id: string, name: string }|null>('call', () => null);
const centrifuge = ref<Centrifuge|null>(null);
const uploadVideoList = useState<UploadVideoData[]>('uploadVideoList', () => []);
//Уведомления
const notifications = useState<Notification[]>('notifications', () => []);
const newNotificationsCount = useState<number>('newNotificationsCount', () => 0);
const newMessagesCount = useState<NewMessageCount[]>('newMessagesCount', () => []);
const newMessageNotification = ref();

//-----COMPUTED-----\\
watch(() => client.isAuthenticated, (isAuthenticated: boolean) => {
  if (isAuthenticated) {
    auth();
  } else {
    clearTimeout(pingTimer);
    centrifugeDisconnect();
  }
});

//-----METHODS-----\\
function auth() {
  getNewMessage();
  centrifugeConnect();
  ping();
}
async function centrifugeConnect() {
  if (!client.isAuthenticated || centrifuge.value !== null) {
    return;
  }
  const { data } = await mainFetch('chats/auth', { method: 'POST' }, true);//TODO: Проверка
  centrifuge.value = new Centrifuge(
    (runtimeConfig.public.protocol === 'https' ? 'wss' : 'ws') + '://chat.' + runtimeConfig.public.domain + '/connection/websocket',
    { token: data.value.token }
  );
  unref(centrifuge).connect();
  webSocketNotification();
  webSocketChat();
}
function centrifugeDisconnect() {
  if (centrifuge.value === null) {
    return;
  }
  unref(centrifuge).disconnect();
  centrifuge.value = null;
}
async function webSocketNotification() {
  await get(1, 3).then(response => {
    response = response.data.value??response.error.value?.data;
    if (response.error.code !== 200) {
      return;
    }
    
    callHook('notifications:loaded', response.notifications.count !== 0);
    newNotificationsCount.value = response.notifications.newCount;
    notifications.value = response.notifications.items.map(item => {
      item.created = new Date(item.created * 1000);
      return item;
    });
  });

  unref(centrifuge).on('publication', ctx => {
    if (ctx.channel !== 'notifications:#' + client.getClientId) {
      return;
    }

    if (ctx.data.event === 'new') {
      if (notifications.value.length > 2) {
        notifications.value.pop();
      }

      ctx.data.notification.created = new Date(ctx.data.notification.created * 1000);
      notifications.value.unshift(ctx.data.notification);
      newNotificationsCount.value++;

      $toast(ctx.data.notification.title, {
        icon: false,
        toastClassName: 'border border-primary bg-white text-dark primary',
      });
    } else if (ctx.data.event === 'channelConnected') {
      callHook('channel-connected', ctx.data.channel);
    }
  });
}
function playChatNotification() {
  newMessageNotification.value.currentTime = 0;
  newMessageNotification.value.play();
}
async function getNewMessage() {
  await mainFetch('chats/all/number-new', {}, true).then(response => {
    newMessagesCount.value = response.data.value.numberNew;
  });
}
function webSocketChat() {
  unref(centrifuge).on('publication', ctx => {
    if (ctx.channel !== 'chats:#' + client.getClientId) {
      return;
    }

    if (ctx.data.event === 'newMessage') {
      // newMessagesCount.value++;
      playChatNotification();
    }

    callHook('chat', ctx.data);
  });
}
function ping() {
  if (!client.isAuthenticated) {
    return;
  }

  mainFetch('clients/ping', {}, true).then(() => {
    pingTimer = setTimeout(ping, 120000);
  });
}
function connectFaro() {
  if (import.meta.dev) {
    return;
  }

  const faro = initializeFaro({
    url: 'https://rum.sobes.ru/collect',
    apiKey: '50158727-bc58-47f1-a427-a9564c573879',
    app: {
      name: 'sobes-frontend',
      version: '1.0.0',
    },
    instrumentations: [
      ...getWebInstrumentations()
    ]
  });

  router.afterEach((to, from) => {
    faro.api.pushEvent(EVENT_ROUTE_CHANGE, {
      toRoute: to.path,
      toUrl: to.fullPath,
      fromRoute: from.path,
      fromUrl: from.fullPath,
    });
  });
}

onMounted(async () => {
  await nextTick();
  if (client.isAuthenticated) {
    auth();
  }
  connectFaro();
});
onUnmounted(() => {
  centrifugeDisconnect();
});
</script>