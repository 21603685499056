<template>
  <vSelect
    :options="optionsPrivate"
    label="title"
    :multiple="isMultiple"
    @search="onSearch"
  >
    <template #no-options="{ search, searching }">
      <template v-if="searching">
        По запросу "<b>{{ search }}</b>" ничего не найдено.
      </template>
      <template v-else>
        {{ hint === undefined ? 'Начните писать...' : hint }}
      </template>
    </template>

    <template
      v-if="isLabelFun"
      #option="option"
    >
      {{ labelFun(option) }}
    </template>
    <template
      v-if="isLabelFun"
      #selected-option="option"
    >
      {{ labelFun(option) }}
    </template>
    <template
      v-if="!!$slots['option']"
      #option="option"
    >
      <slot
        name="option"
        :option="option"
      />
    </template>
    <template
      v-if="!!$slots['option']"
      #option-option="option"
    >
      <slot
        name="option"
        :option="option"
      />
    </template>

    <template #open-indicator="{ attributes }">
      <svg
        v-bind="attributes"
        class="svg-inject svg-icon"
        :class="isSorting ? 'd-none d-md-block' : null"
        style="fill: #AD00FF;"
        height="25"
        width="25"
      >
        <use xlink:href="/public/icons/main.svg#down" />
      </svg>
      <svg
        v-if="isSorting"
        v-bind="attributes"
        class="d-md-none d-block"
        style="fill: #AD00FF"
        height="30"
        width="30"
      >
        <use xlink:href="/public/icons/main.svg#page-info" />
      </svg>
    </template>
  </vSelect>
</template>

<script setup lang="ts">
import vSelect from 'vue-select';
import debounce from 'lodash/debounce';
import type { PropType } from "vue";

//-----PROPS-----\\
const props = defineProps({
  options: { type: Object as PropType<any>, default: () => {} },
  labelFun: { type: Object as PropType<any>, default: undefined },
  hint: { type: String as PropType<string|undefined>, default: undefined },
  getter: { type: Function, required: false, default: undefined },
  urlGetter: { type: Function, required: false, default: undefined },
  target: { type: String, default: '' },
  isDebounce: { type: Boolean, required: false, default: true },
  debounceWait: { type: Number, required: false, default: 350 },
  isSorting: {type: Boolean, default: false},
  isLoading: { type: Boolean, default: false },
  isMultiple: { type: Boolean, default: false },
});

//-----STATE-----\\
const optionsPrivate = ref(props.options);
const isLabelFun = ref(props.labelFun !== undefined);

//-----COMPUTED-----\\
watch(() => props.options, () => {
  optionsPrivate.value = props.options;
});

//-----METHODS-----\\
let searchDeb: debounce;
if (props.isDebounce === true) {
  searchDeb = debounce(get, props.debounceWait??350);
}

function onSearch(title: string, loading: Function) {
  if ((props.urlGetter === undefined && props.getter === undefined) || title.length < 3) {
    if (props.isDebounce === true) {
      searchDeb.cancel();
    }
    loading(false);
    return;
  }

  loading(true);
  if (props.isDebounce === false) {
    get(title, loading);
    return;
  }
  searchDeb(title, loading);
}
async function get(title: string, loading: Function) {
  if (props.getter !== undefined) {
    optionsPrivate.value = await props.getter(title)
  } else {
    const { data } = await mainFetch(props.urlGetter(title));
    optionsPrivate.value = data.value[props.target];
  }

  loading(false);
}
</script>
