import type { Notification } from "~/entities/notification";
import type { ApiResponse } from "~/entities/apiResponse";

type GetResponse = ApiResponse & {
    notifications: {
        items: Notification[];
        count: number;
    };
};

export const useApiNotifications = (clientId: string) => {
    return {
        get: async (page: number = 1, perPage: number = 10) => {
            return await mainFetch(`clients/${clientId}/notifications`, {
                query: {
                    page,
                    perPage
                },
            });
        },
        setVisible: async (ids: string[]) => {
            return await mainFetch(`clients/${clientId}/notifications`, {
                method: 'PUT',
                body: {
                    notifications: ids,
                    isViewed: true
                }
            });
        },
        channels: {
            create: async (channel: number) => {
                return mainFetch(`clients/${clientId}/notifications/channels`, {
                    method: 'POST',
                    body: { channel }
                });
            },
            get: async () => {
                return await mainFetch(`clients/${clientId}/notifications/channels`);
            },
            delete: async (channel: number) => {
                return mainFetch(`clients/${clientId}/notifications/channels/${channel}`, {
                    method: 'DELETE'
                });
            }
        },
    };
}
