import revive_payload_client_4sVQNw7RlN from "/webapp/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/webapp/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/webapp/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/webapp/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/webapp/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/webapp/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/webapp/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/webapp/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/webapp/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/webapp/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import swiper_wnFZ5tA1pM from "/webapp/.nuxt/swiper.mjs";
import plugin_tbFNToZNim from "/webapp/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import _1_mainFetch_ugyVso6pbz from "/webapp/plugins/1.mainFetch.ts";
import _2_store_LQyRiZxwAY from "/webapp/plugins/2.store.ts";
import _4_toastification_2eVAUKuYL9 from "/webapp/plugins/4.toastification.ts";
import _5_vClickOutside_n9IllJXypU from "/webapp/plugins/5.vClickOutside.ts";
import _6_vue_final_modal_PXVTxzgIXR from "/webapp/plugins/6.vue-final-modal.ts";
import _7_vue_toastificaton_client_lrZl4tV8h7 from "/webapp/plugins/7.vue-toastificaton.client.ts";
import _8_maska_S0WJVMZEQ7 from "/webapp/plugins/8.maska.ts";
import vue_ellipse_progress_client_L5fH7vVfmU from "/webapp/plugins/vue-ellipse-progress.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  swiper_wnFZ5tA1pM,
  plugin_tbFNToZNim,
  _1_mainFetch_ugyVso6pbz,
  _2_store_LQyRiZxwAY,
  _4_toastification_2eVAUKuYL9,
  _5_vClickOutside_n9IllJXypU,
  _6_vue_final_modal_PXVTxzgIXR,
  _7_vue_toastificaton_client_lrZl4tV8h7,
  _8_maska_S0WJVMZEQ7,
  vue_ellipse_progress_client_L5fH7vVfmU
]