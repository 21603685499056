<template>
  <vSelect
    :options="optionsPrivate"
    :label="label"
    :clear-search-on-blur="() => false"
    :clear-search-on-select="false"
    :dropdown-should-open="(VueSelect) => (modelValue !== undefined && modelValue.trim().length !== 0) && VueSelect.open"
    :is-filter="false"
    :clearable="false"
    @search="onSearch"
    @option:selecting="onSelecting"
  >
    <template #search="{ attributes, events }">
      <input
        type="text"
        class="vs__search text-dark opacity-100"
        :value="modelValue"
        v-bind="{ ...attributes, value: props.modelValue }"
        v-on="events"
        @input="onInput"
      >
    </template>

    <template #no-options="{ search, searching }">
      <template v-if="searching">
        По запросу "<b>{{ search }}</b>" ничего не найдено.
      </template>
      <template v-else>
        {{ hint === undefined ? 'Начните писать...' : hint }}
      </template>
    </template>
    <template #open-indicator>
      <button
        v-show="modelValue?.length > 0"
        class="vs__clear"
        @click.stop.prevent="onClear"
      >
        <svg
          height="20"
          width="20"
        >
          <use xlink:href="/public/icons/main.svg#close" />
        </svg>
      </button>
    </template>
  </vSelect>
</template>

<script setup lang="ts">
import vSelect from 'vue-select';
import debounce from 'lodash/debounce';
import type { PropType } from "vue";

//-----PROPS-----\\
const props = defineProps({
  modelValue: { type: String, default: undefined },
  label: { type: String, default: 'title' },
  options: { type: Object as PropType<any>, default: () => {} },
  hint: { type: String as PropType<string|undefined>, default: undefined },
  getter: { type: Function, default: undefined },
  urlGetter: { type: Function, default: undefined },
  target: { type: String, default: undefined },
});
const emits = defineEmits(['update:modelValue']);

//-----STATE-----\\
const optionsPrivate = ref(props.options);

//-----METHODS-----\\
const searchDeb: debounce = debounce(get, 350);
function onInput(e: Event) {
  const target = e.target as HTMLInputElement;
  emits('update:modelValue', target.value);
}
function onSearch(title: string, loading: Function) {
  if ((props.urlGetter === undefined && props.getter === undefined) || title.length < 3) {
    if (props.debounce === true) {
      searchDeb.cancel();
    }
    loading(false);
    return;
  }

  loading(true);
  if (props.debounce === false) {
    get(title, loading);
    return;
  }
  searchDeb(title, loading);
}
function onSelecting(item: Object) {
  emits('update:modelValue', item[props.label]);
}
async function get(title: string, loading: Function) {
  if (props.getter !== undefined) {
    optionsPrivate.value = await props.getter(title)
  } else {
    const { data } = await mainFetch(props.urlGetter(title));
    optionsPrivate.value = data.value[props.target];
  }

  loading(false);
}
function onClear() {
  emits('update:modelValue', '');
}
</script>
