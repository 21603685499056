import { default as _91_46_46_46404_93Kn9WX7WVMgMeta } from "/webapp/pages/[...404].vue?macro=true";
import { default as aboutKzC5DIwjlUMeta } from "/webapp/pages/about.vue?macro=true";
import { default as _91id_93tbBdsp36HUMeta } from "/webapp/pages/admin/clients/[id].vue?macro=true";
import { default as indexJahkC1LT7CMeta } from "/webapp/pages/admin/clients/index.vue?macro=true";
import { default as _91id_93DWJ5YLrPUQMeta } from "/webapp/pages/admin/companies/[id].vue?macro=true";
import { default as indexjq4UGHYcy9Meta } from "/webapp/pages/admin/companies/index.vue?macro=true";
import { default as _91id_93LSgwp0bV8pMeta } from "/webapp/pages/admin/complaint/[id].vue?macro=true";
import { default as indexKBr1xyahtZMeta } from "/webapp/pages/admin/complaint/index.vue?macro=true";
import { default as dashboardGjCSyDjgWfMeta } from "/webapp/pages/admin/dashboard.vue?macro=true";
import { default as _91id_93AhopErHtBaMeta } from "/webapp/pages/admin/help-requests/[id].vue?macro=true";
import { default as indexJ8P54Jf8ZfMeta } from "/webapp/pages/admin/help-requests/index.vue?macro=true";
import { default as _91id_93IE5xjdW91oMeta } from "/webapp/pages/admin/notifications/[id].vue?macro=true";
import { default as indexILy4eya7mhMeta } from "/webapp/pages/admin/notifications/index.vue?macro=true";
import { default as index9soSwBhOH7Meta } from "/webapp/pages/admin/summaries/index.vue?macro=true";
import { default as archivetJp7cu4JDFMeta } from "/webapp/pages/applicant/archive.vue?macro=true";
import { default as colloquiesLm0n7t2lzGMeta } from "/webapp/pages/applicant/colloquies.vue?macro=true";
import { default as indexkmPA1nLGMmMeta } from "/webapp/pages/applicant/interviews/index.vue?macro=true";
import { default as responses70AhnI8ezDMeta } from "/webapp/pages/applicant/responses.vue?macro=true";
import { default as applicant903BRDQlxTMeta } from "/webapp/pages/applicant.vue?macro=true";
import { default as chatsOdLoH7HisuMeta } from "/webapp/pages/chats.vue?macro=true";
import { default as _91id_938knnGcQZLsMeta } from "/webapp/pages/companies/[id].vue?macro=true";
import { default as indexLbundN7RKlMeta } from "/webapp/pages/companies/index.vue?macro=true";
import { default as _91employee_id_93GIOwi8JzIWMeta } from "/webapp/pages/company/[id]/settings/[employee_id].vue?macro=true";
import { default as index11KfLm24bpMeta } from "/webapp/pages/company/[id]/settings/index.vue?macro=true";
import { default as _91vacancy_id_93A70D6ektlnMeta } from "/webapp/pages/company/[id]/vacancies/[vacancy_id].vue?macro=true";
import { default as indexf5pIauh1OwMeta } from "/webapp/pages/company/[id]/vacancies/index.vue?macro=true";
import { default as signupiwBPWjjoZ5Meta } from "/webapp/pages/company/signup.vue?macro=true";
import { default as documentsTDGlzt9R9QMeta } from "/webapp/pages/documents.vue?macro=true";
import { default as favoritesNBOwGGEkyzMeta } from "/webapp/pages/favorites.vue?macro=true";
import { default as forgotpassvbjzk435eiMeta } from "/webapp/pages/forgotpass.vue?macro=true";
import { default as indexRqyRKhUtCQMeta } from "/webapp/pages/help/index.vue?macro=true";
import { default as indexxwoz2jbduuMeta } from "/webapp/pages/index.vue?macro=true";
import { default as loginZgqWSJcJqoMeta } from "/webapp/pages/login.vue?macro=true";
import { default as indexgQKdLGBTDvMeta } from "/webapp/pages/notifications/index.vue?macro=true";
import { default as settingsl5lZovrP97Meta } from "/webapp/pages/settings.vue?macro=true";
import { default as signupvve4mb1mYQMeta } from "/webapp/pages/signup.vue?macro=true";
import { default as _91summary_id_93gM1KIizxhHMeta } from "/webapp/pages/summaries/[summary_id].vue?macro=true";
import { default as _91vacancy_id_93Fl1lymlgkvMeta } from "/webapp/pages/vacancies/[vacancy_id].vue?macro=true";
import { default as indexOwO1aERSGGMeta } from "~/pages/search/index.vue?macro=true";
import { default as indexvpjJqeNvCoMeta } from "~/pages/applicant/summaries/index.vue?macro=true";
import { default as indexo671e091OCMeta } from "~/pages/applicant/interviews/[response_id]/index.vue?macro=true";
import { default as editdoaDKze2EbMeta } from "~/pages/company/[id]/vacancies/[vacancy_id]/edit.vue?macro=true";
import { default as responses2JQUZcRuxpMeta } from "~/pages/company/[id]/vacancies/[vacancy_id]/responses.vue?macro=true";
import { default as _91vacancy_id_93srseIvp6gyMeta } from "~/pages/company/[id]/vacancies/[vacancy_id].vue?macro=true";
export default [
  {
    name: "404",
    path: "/:404(.*)*",
    component: () => import("/webapp/pages/[...404].vue").then(m => m.default || m)
  },
  {
    name: "about",
    path: "/about",
    component: () => import("/webapp/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "admin-clients-id",
    path: "/admin/clients/:id()",
    meta: _91id_93tbBdsp36HUMeta || {},
    component: () => import("/webapp/pages/admin/clients/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-clients",
    path: "/admin/clients",
    meta: indexJahkC1LT7CMeta || {},
    component: () => import("/webapp/pages/admin/clients/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-companies-id",
    path: "/admin/companies/:id()",
    meta: _91id_93DWJ5YLrPUQMeta || {},
    component: () => import("/webapp/pages/admin/companies/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-companies",
    path: "/admin/companies",
    meta: indexjq4UGHYcy9Meta || {},
    component: () => import("/webapp/pages/admin/companies/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-complaint-id",
    path: "/admin/complaint/:id()",
    meta: _91id_93LSgwp0bV8pMeta || {},
    component: () => import("/webapp/pages/admin/complaint/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-complaint",
    path: "/admin/complaint",
    meta: indexKBr1xyahtZMeta || {},
    component: () => import("/webapp/pages/admin/complaint/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-dashboard",
    path: "/admin/dashboard",
    meta: dashboardGjCSyDjgWfMeta || {},
    component: () => import("/webapp/pages/admin/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "admin-help-requests-id",
    path: "/admin/help-requests/:id()",
    meta: _91id_93AhopErHtBaMeta || {},
    component: () => import("/webapp/pages/admin/help-requests/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-help-requests",
    path: "/admin/help-requests",
    meta: indexJ8P54Jf8ZfMeta || {},
    component: () => import("/webapp/pages/admin/help-requests/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-notifications-id",
    path: "/admin/notifications/:id()",
    meta: _91id_93IE5xjdW91oMeta || {},
    component: () => import("/webapp/pages/admin/notifications/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-notifications",
    path: "/admin/notifications",
    meta: indexILy4eya7mhMeta || {},
    component: () => import("/webapp/pages/admin/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-summaries",
    path: "/admin/summaries",
    meta: index9soSwBhOH7Meta || {},
    component: () => import("/webapp/pages/admin/summaries/index.vue").then(m => m.default || m)
  },
  {
    name: "applicant",
    path: "/applicant",
    component: () => import("/webapp/pages/applicant.vue").then(m => m.default || m),
    children: [
  {
    name: "applicant-archive",
    path: "archive",
    component: () => import("/webapp/pages/applicant/archive.vue").then(m => m.default || m)
  },
  {
    name: "applicant-colloquies",
    path: "colloquies",
    component: () => import("/webapp/pages/applicant/colloquies.vue").then(m => m.default || m)
  },
  {
    name: "applicant-interviews",
    path: "interviews",
    component: () => import("/webapp/pages/applicant/interviews/index.vue").then(m => m.default || m)
  },
  {
    name: "applicant-responses",
    path: "responses",
    component: () => import("/webapp/pages/applicant/responses.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "chats",
    path: "/chats",
    component: () => import("/webapp/pages/chats.vue").then(m => m.default || m)
  },
  {
    name: "companies-id",
    path: "/companies/:id()",
    component: () => import("/webapp/pages/companies/[id].vue").then(m => m.default || m)
  },
  {
    name: "companies",
    path: "/companies",
    component: () => import("/webapp/pages/companies/index.vue").then(m => m.default || m)
  },
  {
    name: "company-id-settings-employee_id",
    path: "/company/:id()/settings/:employee_id()",
    component: () => import("/webapp/pages/company/[id]/settings/[employee_id].vue").then(m => m.default || m)
  },
  {
    name: "company-id-settings",
    path: "/company/:id()/settings",
    component: () => import("/webapp/pages/company/[id]/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "company-id-vacancies-vacancy_id",
    path: "/company/:id()/vacancies/:vacancy_id()",
    component: () => import("/webapp/pages/company/[id]/vacancies/[vacancy_id].vue").then(m => m.default || m)
  },
  {
    name: "company-id-vacancies",
    path: "/company/:id()/vacancies",
    component: () => import("/webapp/pages/company/[id]/vacancies/index.vue").then(m => m.default || m)
  },
  {
    name: "company-signup",
    path: "/company/signup",
    component: () => import("/webapp/pages/company/signup.vue").then(m => m.default || m)
  },
  {
    name: "documents",
    path: "/documents",
    component: () => import("/webapp/pages/documents.vue").then(m => m.default || m)
  },
  {
    name: "favorites",
    path: "/favorites",
    component: () => import("/webapp/pages/favorites.vue").then(m => m.default || m)
  },
  {
    name: "forgotpass",
    path: "/forgotpass",
    component: () => import("/webapp/pages/forgotpass.vue").then(m => m.default || m)
  },
  {
    name: "help",
    path: "/help",
    component: () => import("/webapp/pages/help/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/webapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/webapp/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "notifications",
    path: "/notifications",
    component: () => import("/webapp/pages/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/webapp/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: "signup",
    path: "/signup",
    component: () => import("/webapp/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: "summaries-summary_id",
    path: "/summaries/:summary_id()",
    component: () => import("/webapp/pages/summaries/[summary_id].vue").then(m => m.default || m)
  },
  {
    name: "vacancies-vacancy_id",
    path: "/vacancies/:vacancy_id()",
    component: () => import("/webapp/pages/vacancies/[vacancy_id].vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/:city/:source(vacancies|summaries|companies)/:profession?",
    component: () => import("~/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexvpjJqeNvCoMeta?.name,
    path: "/applicant/summaries",
    component: () => import("~/pages/applicant/summaries/index.vue").then(m => m.default || m)
  },
  {
    name: indexo671e091OCMeta?.name,
    path: "/applicant/interviews/:response_id",
    component: () => import("~/pages/applicant/interviews/[response_id]/index.vue").then(m => m.default || m)
  },
  {
    name: editdoaDKze2EbMeta?.name,
    path: "/company/:id/vacancies/create",
    component: () => import("~/pages/company/[id]/vacancies/[vacancy_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editdoaDKze2EbMeta?.name,
    path: "/company/:id/vacancies/:vacancy_id/edit",
    component: () => import("~/pages/company/[id]/vacancies/[vacancy_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: _91vacancy_id_93srseIvp6gyMeta?.name,
    path: "/company/:id/vacancies/:vacancy_id",
    component: () => import("~/pages/company/[id]/vacancies/[vacancy_id].vue").then(m => m.default || m),
    children: [
  {
    name: responses2JQUZcRuxpMeta?.name,
    path: "responses",
    component: () => import("~/pages/company/[id]/vacancies/[vacancy_id]/responses.vue").then(m => m.default || m)
  },
  {
    name: responses2JQUZcRuxpMeta?.name,
    path: "interviews",
    component: () => import("~/pages/company/[id]/vacancies/[vacancy_id]/responses.vue").then(m => m.default || m)
  },
  {
    name: responses2JQUZcRuxpMeta?.name,
    path: "colloquies",
    component: () => import("~/pages/company/[id]/vacancies/[vacancy_id]/responses.vue").then(m => m.default || m)
  },
  {
    name: responses2JQUZcRuxpMeta?.name,
    path: "archive",
    component: () => import("~/pages/company/[id]/vacancies/[vacancy_id]/responses.vue").then(m => m.default || m)
  }
]
  }
]