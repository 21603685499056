type _CreateBody = {
    name: string,
    tin: string,
    typeCompany: number
};

export const useApiCompanies = () => {
    return {
        /**
         * Регистрация компании
         * @param body
         */
        create: (body: _CreateBody) => mainFetch('companies', { method: 'POST', body }),
    };
}
