<template>
  <footer class="footer bg-black mt-43">
    <div class="container-xxl">
      <div class="row px-20 pb-80 pt-43 flex-lg-row flex-column text-white gx-main justify-content-between align-items-start">
        <div class="col-lg-3 m-0 col-12 d-flex flex-column gap-10">
          <NuxtLink
            class="navbar-brand mx-md-0 p-0 d-flex align-items-center"
            to="/"
          >
            <img
              src="/public/logo-only.svg"
              alt=""
            >
            <span
              class="fw-bold ms-5 text-white"
              style="font-size: 30px"
            >
              SOBES
            </span>
          </NuxtLink>
          <div class="col-xl-2 m-0 w-0 d-xl-none d-block">
            <div class="row gap-10 justify-content-start">
              <a
                href="/"
                class="svg-size"
              >
                <svg class="svg-size">
                  <use xlink:href="/public/icons/telegram.svg#telegram" />
                </svg>
              </a>
              <a
                href="/"
                class="svg-size"
              >
                <svg class="svg-size">
                  <use xlink:href="/public/icons/vk.svg#vk" />
                </svg>
              </a>
            </div>
          </div>
          <p class="mb-0">
            Sobes.ru - © 2023 Все права защищены
          </p>
        </div>
        <div class="col-lg-auto gap-10 col-12 m-lg-0 mt-34 d-flex flex-column">
          <NuxtLink
            class="navbar-brand text-white mx-md-0 link-size"
            :to="`/${searchCity?.alias}/vacancies`"
          >
            Найти работу
          </NuxtLink>
          <NuxtLink
            class="navbar-brand text-white mx-md-0 link-size"
            :to="`/${searchCity?.alias}/summaries`"
          >
            Найти сотрудников
          </NuxtLink>
          <NuxtLink
            class="navbar-brand text-white mx-md-0 link-size"
            to="/companies"
          >
            Компании
          </NuxtLink>
        </div>
        <div class="col-lg-auto gap-10 col-12 m-lg-0 mt-34 d-flex flex-column">
          <div class="heading-text-1 fw-bold">
            Sobes
          </div>
          <NuxtLink
            class="navbar-brand text-white mx-md-0 link-size"
            to="/about?theme=1"
          >
            О сервисе
          </NuxtLink>
          <NuxtLink
            class="navbar-brand text-white mx-md-0 link-size"
            to="/about?theme=2"
          >
            Контакты
          </NuxtLink>
        </div>
        <div class="col-lg-auto gap-10 col-12 m-lg-0 mt-34 d-flex flex-column">
          <div class="heading-text-1 fw-bold">
            Помощь
          </div>
          <NuxtLink
            class="navbar-brand text-white mx-md-0 link-size"
            to="/help?theme=1"
          >
            Для соискателя
          </NuxtLink>
          <NuxtLink
            class="navbar-brand text-white mx-md-0 link-size"
            to="/help?theme=2"
          >
            Для работодателя
          </NuxtLink>
          <NuxtLink
            class="navbar-brand text-white mx-md-0 link-size"
            to="/help?theme=3"
          >
            Служба поддержки
          </NuxtLink>
        </div>
        <div class="gap-10 col-lg-auto col-12 m-lg-0 mt-34 d-flex flex-column">
          <div class="heading-text-1 fw-bold">
            Документы
          </div>
          <NuxtLink
            class="navbar-brand text-white mx-md-0 link-size"
            to="/documents?theme=1"
          >
            Политика конфидициальности
          </NuxtLink>
          <NuxtLink
            class="navbar-brand text-white mx-md-0 link-size"
            to="/documents?theme=2"
          >
            Пользовательское соглашение
          </NuxtLink>
        </div>
        <div class="col-xl-2 m-0 w-0 d-xl-block d-none">
          <div class="row gap-10 justify-content-start">
            <a
              href="/"
              class="svg-size"
            >
              <svg class="svg-size">
                <use xlink:href="/public/icons/telegram.svg#telegram" />
              </svg>
            </a>
            <a
              href="/"
              class="svg-size"
            >
              <svg class="svg-size">
                <use xlink:href="/public/icons/vk.svg#vk" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
const searchCity = useState<City>('searchCity');
</script>

<style scoped lang="scss">
.svg-size {
  width: 50px;
  height: 50px;
}

.link-size {
  font-size: 16px;
}
</style>
